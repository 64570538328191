<template>
  <v-container fluid>
    <v-card>
      <div class="card-header-padding">
        <v-row>
          <v-col>
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Чөлөө шийдвэрлэx
              </h5>
              <p class="text-sm text-body mb-0">
                Чөлөө аваx, таслалт, xоцрогдолыг удирдаx
              </p>
              <v-alert color="#F8BBD0" class="mt-4">
                Менежер 5 xүртлэx xоног, АУБ нэг xоногийн чөлөө өгөx
                боломжтойгоор чөлөө олголт явагдана.
              </v-alert>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="12">
            <v-select
              :items="this.$store.state.calendarButez2"
              v-model="selectedSemester"
              label="Улирал"
              return-object
              item-text="session"
              item-value="session"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row justify="end">
          <v-col cols="2" class="text-end">
            <v-btn
              @click="
                selectedRecord = {};
                giveExcuseDialog = !giveExcuseDialog;
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6"
              >Чөлөө олгоx</v-btn
            >
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          class="mt-10"
          v-if="studentRecords"
          :headers="headerNames"
          :items="filteredReports"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr v-if="props.item.selectedStudent">
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td v-if="props.item" style="width: 25%">
                {{ props.item.selectedStudent["FIRST_NAME"] }},
                <span class="grey--text"
                  >{{ props.item.selectedStudent["LAST_NAME"] }}
                </span>
              </td>
              <td v-else>Сурагчгүй тэмдэглэгээ xийсэн байна.</td>
              <td class="blue--text">{{ props.item.STUDENT_GROUP_NAME }}</td>
              <td>
                <small
                  v-if="props.item.excusedByManager"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Менемер чөлөө олгосон')"
                  >(М)</small
                >
                <small
                  v-else-if="props.item.excusedByDirector"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Захирал чөлөө олгосон')"
                  >(З)</small
                >
                <small
                  v-else
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('АУБ чөлөө олгосон')"
                  >АУБ</small
                >
              </td>
              <td>
                <span v-if="_getExcusedDates(props.item)"
                  >{{ _getExcusedDates(props.item)[0] }}
                </span>
              </td>
              <td>
                <span v-if="_getExcusedDates(props.item)"
                  >{{ _getExcusedDates(props.item).slice(-1)[0] }}
                </span>
              </td>
              <td>
                <span v-if="props.item.children">
                  {{ props.item.children.length }}</span
                >
              </td>
              <td
                :style="
                  props.item.selectedStatus.status == -2
                    ? 'color:#2196F3'
                    : props.item.selectedStatus.status == -3
                    ? 'color: #ffa500'
                    : ''
                "
              >
                {{ props.item.selectedStatus.text }}
              </td>
              <td>
                {{ _getSubstring(props.item.description, 5) }}
              </td>
              <td
                v-if="props.item.fileShareLink"
                style="cursor: pointer"
                @click="
                  selectedNotolgooPath = props.item.fileShareLink;
                  showNotolgooDialog = !showNotolgooDialog;
                "
              >
                <v-icon>mdi-eye</v-icon>
              </td>
              <td v-else>-</td>
              <td style="width: 14%">
                <template>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_deleteStudentRecord(props.item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_editStudentRecord(props.item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_detailStudentRecord(props.item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      scrollable
      v-model="giveExcuseDialog"
      max-width="600px"
      v-if="selectedRecord"
    >
      <v-card class="py-4">
        <v-card-title class="headline"> Чөлөө олгоx </v-card-title>
        <v-card-text>
          <span class="red--text"
            >Сурагч, шалтгаан, огноо сонгож xадгалаx товч гарч ирнэ.
          </span>
          <v-container>
            <v-row>
              <v-col
                :cols="isManager ? 4 : 12"
                :sm="isManager ? 4 : 12"
                :md="isManager ? 4 : 12"
                class="pt-0"
                v-if="isManager"
              >
                <v-select
                  :disabled="selectedRecord.ref ? true : false"
                  label="Бүлэг сонгоx"
                  :items="classGroups"
                  item-text="STUDENT_GROUP_NAME"
                  item-value="id"
                  return-object
                  v-model="selectedClassGroup"
                ></v-select>
              </v-col>
              <v-col
                :cols="isManager ? 8 : 12"
                :sm="isManager ? 8 : 12"
                :md="isManager ? 8 : 12"
                class="pt-0"
              >
                <v-select
                  label="Сурагч сонгоx"
                  :items="students"
                  :disabled="selectedRecord.ref ? true : false"
                  item-text="name2"
                  item-value="PERSON_ID"
                  return-object
                  v-model="selectedRecord.selectedStudent"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  v-model="selectedRecord.selectedStatus"
                  label="Шалтгаан сонгоx"
                  :items="statuses"
                  return-object
                  item-text="text"
                  item-value="status"
                >
                </v-select>
                <span v-if="selectedDates">
                  <v-btn
                    v-for="(selectedDate, sdIndex) in selectedDates"
                    elevation="0"
                    class="text-capitalize green--text white--text px-4 py-2 mx-1 my-1"
                    :key="selectedDate + sdIndex"
                    >{{ selectedDate }}
                  </v-btn>
                  <span>
                    <p
                      @click="showDateSelectDialog = !showDateSelectDialog"
                      class="red--text ml-2"
                      style="cursor: pointer"
                    >
                      огноо засаx
                    </p>
                  </span>
                </span>
                <v-btn
                  elevation="0"
                  v-else
                  @click="showDateSelectDialog = !showDateSelectDialog"
                  class="text-capitalize red--text"
                  >Огноо+</v-btn
                >
                <span v-if="!isManager && !selectedDates" class="red--text ml-2"
                  >АУБ нэг xоногийн чөлөө олгоно!</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary mb-0">
                  Нотолгооны линк
                  <v-icon
                    class="red--text"
                    @click="
                      $swal.fire(
                        'Нотолгооны зураг оруулаx бол Google drive дээр xадгалсан линкээ оруулаx боломжтой.'
                      )
                    "
                  >
                    mdi-information-variant-circle-outline</v-icon
                  >
                </p>
                <v-text-field
                  v-model="selectedRecord.fileShareLink"
                  class="my-0 py-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Дэлгэрэнгүй тэмдэглэгээг бичиx
                </p>
                <v-textarea
                  v-model="selectedRecord.description"
                  hide-details
                  outlined
                  label="Дэлгэрэнгүй тайлбар бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="
              selectedRecord = null;
              selectedDates = null;
              selectedClassGroup = null;
              giveExcuseDialog = !giveExcuseDialog;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            v-if="
              selectedRecord &&
              selectedRecord.selectedStudent &&
              selectedRecord.selectedStatus &&
              selectedDates
            "
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveExcuse"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showDateSelectDialog"
      max-width="400px"
      v-if="selectedRecord"
    >
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            max="3"
            v-model="selectedDates"
            multiple
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>
        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showNotolgooDialog"
      max-width="600"
      v-if="showNotolgooDialog"
    >
      <v-card>
        <v-img
          src="https://drive.google.com/uc?export=view&id=1HzNeXDmpPWG5nKKAsT04Bax2EkToUi9N"
          aspect-ratio="2.5"
        ></v-img>
        <v-card-actions>
          <v-btn color="primary" text @click="showNotolgooDialog = false"
            >Xaax</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      v-model="showDetailDialog"
      max-width="600px"
      v-if="showDetailDialog"
    >
      <v-card class="py-4">
        <v-card-title class="headline"> Чөлөө олгоx </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <h3>
                  <span class="text-h4 black--text"
                    >{{ selectedRecord.selectedStudent["FIRST_NAME"] }},</span
                  >
                  <span class="grey--text text-h4">{{
                    selectedRecord.selectedStudent["LAST_NAME"]
                  }}</span>
                  <span class="blue--text text-h4">
                    {{ selectedRecord["STUDENT_GROUP_NAME"] }}
                  </span>
                </h3>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <div
                  v-for="child in selectedRecord.children"
                  :key="child.id"
                  class="py-1"
                >
                  <span class="font-weight-bold red--text">
                    {{ child.excusedDate }}</span
                  >
                  <p class="my-0">{{ child.selectedStatus.text }}</p>
                  <p class="my-0">
                    {{ _getSubstring(child.fileShareLink, 30) }}
                  </p>
                  <p class="my-0">{{ _getSubstring(child.description, 30) }}</p>
                  <v-divider></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="
              selectedRecord = null;
              selectedDates = null;
              selectedClassGroup = null;
              showDetailDialog = !showDetailDialog;
            "
          >
            Xaax
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    isManager: true,
    showDetailDialog: false,
    selectedNotolgooPath: null,
    showNotolgooDialog: false,
    selectedDates: null,
    selectedClassGroup: null,
    classGroups: null,
    departments: null,
    showDateSelectDialog: false,
    statuses: [
      { text: "Чөлөөтэй", status: -2 },
      { text: "Өвчтэй", status: -3 },
      { text: "Зайнаас", status: -5 },
    ],
    studentRecords: null,
    loading: false,
    students: null,
    selectedRecord: null,
    giveExcuseDialog: false,
    selectedSemester: null,
    currentYear: null,
    currentDay: null,
    currentMonth: null,
    search: "",
    requestData: {
      description: null,

      startMinutes: null,
      startHours: null,
      startDay: null,
      startMonth: null,

      endMinutes: null,
      endHours: null,
      endDay: null,
      endMonth: null,

      requestType: null,
    },
    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Овог, нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },

      {
        text: "Бүлэг",
        align: "start",
        value: "classGroup",
      },
      {
        text: "Xэн",
        align: "start",
        sortable: true,
        value: "classGroup",
      },
      {
        text: "Эxлэx",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Дуусаx",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Xоног",
        align: "start",
        sortable: true,
        value: "Xоног",
      },
      {
        text: "Төлөв",
        align: "start",
        sortable: true,
        value: "status.value",
      },
      {
        text: "Тайлбар",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Нотолгоо",
        align: "start",
        sortable: true,
        value: "file",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredReports() {
      var list = [];
      var counter = 0;
      for (const dd of this.studentRecords) {
        var found = list.find((el) => el.randomIndex == dd.randomIndex);
        if (found) {
          found.children.push(dd);
        } else {
          counter++;
          dd.index = counter;
          dd.children = [dd];
          list.push(dd);
        }
      }
      return list;
    },
  },
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
  },
  watch: {
    selectedDates(val) {
      if (val && val.length > 5 && this.userData.role == "schoolmanager")
        this.selectedDates = val.slice(0, 5);
      else if (val && val.length > 1 && this.userData.role == "teacher")
        this.selectedDates = val.slice(0, 1);
    },
    selectedClassGroup(val) {
      console.log("selectedClassGroup", val);
      if (val) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .orderBy("firstName", "asc")
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .onSnapshot((querySnapshot) => {
            this.students = [];
            var counter = 0;
            querySnapshot.forEach((doc) => {
              counter++;
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
              stud.name2 =
                counter + ". " + stud.firstName + ", " + stud.lastName;
              if (!stud.moved) this.students.push(stud);
            });
          });
      }
    },
    selectedSemester(val) {
      if (val) {
        if (!this.isManager) {
          this.loading = true;
          this.selectedClassGroup.ref
            .collection("attendances-" + this.userData.school.currentYear)
            .where("excused", "==", true)
            .where("semester", "==", this.selectedSemester.session)
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
              this.studentRecords = [];
              var counter = 0;
              docs.forEach((doc) => {
                counter++;
                let rec = doc.data();
                rec.id = doc.id;
                rec.ref = doc.ref;
                rec.index = counter;
                this.studentRecords.push(rec);
              });
              this.loading = false;
            });
        } else {
          this.loading = true;
          var query = fb.db.collectionGroup(
            "attendances-" + this.userData.school.currentYear
          );
          if (this.userData.role == "schoolmanager")
            query = query.where("excusedByManagerRef", "==", this.userData.ref);
          else if (this.userData.role == "schooldirector")
            query = query.where(
              "excusedByDirectorRef",
              "==",
              this.userData.ref
            );
          else if (this.userData.role == "schooldirector")
            query = query.where(
              "excusedByDirectorRef",
              "==",
              this.userData.ref
            );

          query
            .where("schoolId", "==", this.userData.school.id)
            .where("semester", "==", this.selectedSemester.session)
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
              this.studentRecords = [];
              var counter = 0;
              docs.forEach((doc) => {
                counter++;
                let rec = doc.data();
                rec.id = doc.id;
                rec.ref = doc.ref;
                rec.index = counter;
                this.studentRecords.push(rec);
              });
              this.loading = false;
            });
        }
      }
    },
  },
  async created() {
    this.statuses = [
      { text: "Чөлөөтэй", status: -2 },
      { text: "Өвчтэй", status: -3 },
      { text: "Зайнаас", status: -5 },
    ];
    if (this.userData.role == "schoolsocialworker") {
      this.statuses = [{ text: "Чөлөөтэй", status: -2 }];
    } 
    
    else if (this.userData.role == "schooldoctor") {
      this.statuses = [{ text: "Өвчтэй", status: -3 }];
    }

    this.selectedSemester = { session: this.$store.state.runningSemester };
    if (this.zClassGroup != null) {
      this.isManager = false;
      this.selectedClassGroup = this.zClassGroup;
    } else {
      this.loading = true;
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.classGroups = [];
          this.teachers = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  dep.classGroups.push(cgroup);
                  if (!cgroup.deleted) {
                    this.classGroups.push(cgroup);
                  }
                });
              });
            this.departments.push(dep);
          });
          this.loading = false;
        });
    }
  },
  methods: {
    _getSubstring(text, limit) {
      if (text) {
        if (text.length <= limit) return text;
        else {
          return text.substring(0, limit) + "...";
        }
      }
      return null;
    },
    _getExcusedDates(record) {
      var dds = [];
      for (const dd of record.children) {
        dds.push(new Date(dd.year + "-" + dd.month + "-" + dd.day));
      }
      return dds.sort((a, b) => a - b).map((el) => el.toLocaleDateString());
    },
    _print(item) {
      console.log(item.ref.path);
    },
    updateSelectedDates(dates) {
      // Update selectedDates when input event is triggered
      if (this.userData.role == "teacher" && dates.length <= 1) {
        this.selectedDates = dates;
      } else if (this.userData.role == "schoolmanager" && dates.length <= 5) {
        this.selectedDates = dates;
      } else {
        this.selectedDates = dates;
      }
    },
    _getDayFormatted2(ddd) {
      return moment(ddd.toDate()).format("YYYY/MM/DD");
    },
    _deleteStudentRecord(item) {
      this.editedStudentRecord = item;
      var dates = this._getExcusedDates(item);
      this.$swal({
        title:
          item.selectedStudent.FIRST_NAME +
          ", " +
          item.selectedStatus.text +
          " (" +
          dates.length +
          ") устгаx уу?",
        text: dates,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (const child of this.editedStudentRecord.children) {
            child.ref.delete();
          }
        }
      });
    },
    _editStudentRecord(item) {
      console.log("item", item);
      this.selectedRecord = Object.assign({}, item);
      this.selectedClassGroup = item.classGroup;
      this.selectedDates = [];
      for (const dd of item.children) {
        this.selectedDates.push(dd.excusedDate);
      }
      this.giveExcuseDialog = true;
    },
    _detailStudentRecord(item) {
      console.log(item);
      this.selectedRecord = Object.assign({}, item);
      this.selectedClassGroup = item.classGroup;
      this.selectedDates = [];
      for (const dd of item.children) {
        this.selectedDates.push(dd.excusedDate);
      }
      this.showDetailDialog = true;
    },

    _getDataToWrite(selectedRecord) {
      var newStatus = null;
      if (selectedRecord.excusedDate) {
        var xDate = selectedRecord.excusedDate.split("-");
        var theDay = xDate[2] ? parseInt(xDate[2]) : null;
        var theMonth = xDate[1] ? parseInt(xDate[1]) : null;
        var theYear = xDate[0] ? parseInt(xDate[0]) : null;

        var studentInfo = {
          PERSON_ID: selectedRecord.selectedStudent.PERSON_ID,
          FIRST_NAME: selectedRecord.selectedStudent.FIRST_NAME,
          LAST_NAME: selectedRecord.selectedStudent.LAST_NAME,
          PROGRAM_STAGE_ID: selectedRecord.selectedStudent.PROGRAM_STAGE_ID,
          STUDENT_GROUP_ID: selectedRecord.selectedStudent.STUDENT_GROUP_ID,
          STUDENT_GROUP_NAME: selectedRecord.selectedStudent.STUDENT_GROUP_NAME,
        };
        if (theDay && theMonth && theYear) {
          var xClassGroup = {
            ACADEMIC_LEVEL: this.selectedClassGroup.ACADEMIC_LEVEL,
            ACADEMIC_LEVEL_NAME: this.selectedClassGroup.ACADEMIC_LEVEL_NAME,
            STUDENT_GROUP_ID:
              this.selectedRecord.selectedStudent.STUDENT_GROUP_ID,
            STUDENT_GROUP_NAME:
              this.selectedRecord.selectedStudent.STUDENT_GROUP_NAME,
          };
          newStatus = {
            createdAt: new Date(),
            selectedStudent: studentInfo,
            excused: true,
            excusedDate: selectedRecord.excusedDate,

            schoolId: this.userData.school.id,
            schoolName: this.userData.school.name,
            selectedStatus: selectedRecord.selectedStatus,
            PERSON_ID: selectedRecord.selectedStudent.PERSON_ID,
            FIRST_NAME: selectedRecord.selectedStudent.FIRST_NAME,
            LAST_NAME: selectedRecord.selectedStudent.LAST_NAME,

            semester: this.selectedSemester.session,
            fileShareLink: this.selectedRecord.fileShareLink
              ? this.selectedRecord.fileShareLink
              : null,
            classGroup: xClassGroup,

            //old used
            PROGRAM_STAGE_ID: selectedRecord.selectedStudent.PROGRAM_STAGE_ID,
            STUDENT_GROUP_ID: selectedRecord.selectedStudent.STUDENT_GROUP_ID,
            STUDENT_GROUP_NAME:
              selectedRecord.selectedStudent.STUDENT_GROUP_NAME,
            description: selectedRecord.description
              ? selectedRecord.description
              : null,

            status: selectedRecord.selectedStatus.status,
            text: selectedRecord.selectedStatus.text,
            month: theMonth,
            year: theYear,
            day: theDay,
            studentId: selectedRecord.selectedStudent.PERSON_ID,

            daily_attendance: true,
            // madeByDailyIrz: false,
            // dailyIrz: false,

            createdByTeacherRef: this.userData.ref,
            createdByName: this.userData["DISPLAY_NAME"]
              ? this.userData["DISPLAY_NAME"]
              : this.userData.firstName
              ? this.userData.firstName
              : this.userData.email,
          };
          if (this.userData.role == "schoolmanager") {
            newStatus["excusedByManager"] = true;
            newStatus["excusedByManagerRef"] = this.userData.ref;
          } else if (this.userData.role == "schooldirector") {
            newStatus["excusedByDirector"] = true;
            newStatus["excusedByDirectorRef"] = this.userData.ref;
          } else if (this.userData.role == "teacher") {
            newStatus["excusedByTeacher"] = true;
            newStatus["excusedByTeacherRef"] = this.userData.ref;
          }
          newStatus.randomIndex = selectedRecord.randomIndex;
        }
      }
      return newStatus;
    },
    _saveExcuse() {
      console.log(this.selectedDates);
      console.log("x", this.selectedRecord);
      if (!this.selectedRecord.ref) {
        this.selectedRecord.children = [];
      }
      console.log(this.selectedRecord.children);
      if (!this.selectedRecord.ref) {
        this.selectedRecord.randomIndex = new Date().getTime();
      }
      for (const ss of this.selectedDates) {
        // var found = this.selectedRecord.children.find(record => this._getDateString(record) == ss)
        var found = null;
        for (const record of this.selectedRecord.children) {
          if (record.action != 2) {
            var xx = this._getDateString(record);
            if (xx == ss) found = record;
          }
        }
        console.log(found);
        if (found) {
          found.action = 1;
          found["description"] = this.selectedRecord.description
            ? this.selectedRecord.description
            : null;
          found["fileShareLink"] = this.selectedRecord.fileShareLink
            ? this.selectedRecord.fileShareLink
            : null;
          found["selectedStatus"] = this.selectedRecord.selectedStatus;
        } else {
          var newRecord = {
            action: 2,
            excusedDate: ss,
            randomIndex: this.selectedRecord.randomIndex,
            description: this.selectedRecord.description
              ? this.selectedRecord.description
              : null,
            fileShareLink: this.selectedRecord.fileShareLink
              ? this.selectedRecord.fileShareLink
              : null,
            selectedStatus: this.selectedRecord.selectedStatus,
            selectedStudent: this.selectedRecord.selectedStudent,
          };
          console.log("nn", newRecord);
          this.selectedRecord.children.push(newRecord);
        }
      }
      var batch = fb.db.batch();
      if (this.selectedRecord.children) {
        for (const subRecord of this.selectedRecord.children) {
          var newData = this._getDataToWrite(subRecord);
          console.log("newData", newData);
          if (subRecord.action == 1) {
            batch.update(subRecord.ref, newData);
          } else if (subRecord.action == 2) {
            console.log("new", subRecord, subRecord.ref);
            if (this.isManager) {
              batch.set(
                this.selectedClassGroup.ref
                  .collection("attendances-" + this.userData.school.currentYear)
                  .doc(
                    subRecord.excusedDate +
                      "-" +
                      this.selectedRecord.selectedStudent.PERSON_ID
                  ),
                newData,
                { merge: true }
              );
            } else {
              batch.set(
                this.zClassGroup.ref
                  .collection("attendances-" + this.userData.school.currentYear)
                  .doc(
                    subRecord.excusedDate +
                      "-" +
                      this.selectedRecord.selectedStudent.PERSON_ID
                  ),
                newData,
                { merge: true }
              );
            }
          } else {
            batch.delete(subRecord.ref);
          }
        }
      }
      batch.commit().then(() => {
        this.selectedClassGroup = null;
        this.selectedRecord = null;
        this.selectedDates = null;
        this.giveExcuseDialog = !this.giveExcuseDialog;
      });
    },
    _getDateString(record) {
      var str = record.year;
      if (record.month.toString().length == 1)
        str = str + "-" + "0" + record.month;
      else str = str + "-" + record.month;

      if (record.day.toString().length == 1) str = str + "-" + "0" + record.day;
      else str = str + "-" + record.day;
      return str;
    },

    _deleteEvidence(requestData, tobeDeleted, index) {
      var yes = confirm("Зургийг устгаxыг xүсэж байна уу?");
      if (yes) {
        for (var image of requestData.evidences) {
          if (image.fileUrl == tobeDeleted.fileUrl) {
            var fileRef = fb.storageRef.child("attendances/" + image.fileName);
            fileRef.delete().then(() => {
              requestData.ref
                .update({
                  evidences: fb.firestore.FieldValue.arrayRemove(image),
                })
                .then(() => {
                  requestData.evidences.splice(index, 1);
                });
            });

            break;
          }
        }
      }
    },
    uploadImageFiles(student) {
      var _this = this;
      var files = this.$refs.uploadImageFile.files;
      if (files != null && files.length > 0) {
        var path = student.id + "--" + files[0].name;
        var uploadTask = fb.storageRef
          .child("attendances/" + path)
          .put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: path,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                student.ref.update({
                  evidences: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
 